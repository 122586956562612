import React, { useEffect, useState, useMemo } from "react";
import { createClient } from "@supabase/supabase-js";
import "./Cards.scss";
import logo from "../../assets/CARSPACE.svg";
import { format } from "date-fns";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const supabase = createClient(
    "https://glwkzfeedpabgtdnzqqx.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imdsd2t6ZmVlZHBhYmd0ZG56cXF4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ0OTkwMjQsImV4cCI6MjAzMDA3NTAyNH0.VxkAUuYERgJT9qllt5GQZET5S8mU2v_NESPuTiNRHJA"
);

function Cards() {
    const [events, setEvents] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("");
    const [currentLocationPast, setCurrentLocationPast] = useState(""); // New state for past events filter
    const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
    const [currentPagePast, setCurrentPagePast] = useState(1);
    const pageSize = 6;

    useEffect(() => {
        const meetsElement = document.getElementById("meets");
        if (meetsElement && currentPageUpcoming !== 1) {
            meetsElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentPageUpcoming]);

    useEffect(() => {
        const pastElement = document.getElementById("past");
        if (pastElement && currentPagePast !== 1) {
            pastElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentPagePast]);

    useEffect(() => {
        getEvents();
    }, []);

    async function getEvents() {
        const { data } = await supabase.from("events").select();
        setEvents(
            data.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
        );
    }

    const currentDate = useMemo(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);

    const filteredUpcomingEvents = useMemo(() => {
        return events.filter(
            (event) =>
                (currentLocation === "" ||
                    event.location === currentLocation ||
                    currentLocation === "all") &&
                new Date(event.event_date) >= currentDate
        );
    }, [events, currentLocation, currentDate]);

    const filteredPastEvents = useMemo(() => {
        return events.filter(
            (event) =>
                (currentLocationPast === "" ||
                    event.location === currentLocationPast ||
                    currentLocationPast === "all") &&
                new Date(event.event_date) < currentDate
        );
    }, [events, currentLocationPast, currentDate]);

    const paginatedUpcomingEvents = useMemo(() => {
        return filteredUpcomingEvents.slice(
            (currentPageUpcoming - 1) * pageSize,
            currentPageUpcoming * pageSize
        );
    }, [filteredUpcomingEvents, currentPageUpcoming, pageSize]);

    const paginatedPastEvents = useMemo(() => {
        return filteredPastEvents
            .sort((a, b) => new Date(b.event_date) - new Date(a.event_date))
            .slice(
                (currentPagePast - 1) * pageSize,
                currentPagePast * pageSize
            );
    }, [filteredPastEvents, currentPagePast, pageSize]);

    const uniqueLocations = useMemo(() => {
        return [
            ...new Set(
                events
                    .filter(
                        (event) => new Date(event.event_date) >= currentDate
                    )
                    .map((event) => event.location)
            ),
        ]
            .filter((location) => location)
            .sort(); // Filter out empty locations
    }, [events, currentDate]);

    const uniqueLocationsPast = useMemo(() => {
        return [
            ...new Set(
                events
                    .filter((event) => new Date(event.event_date) < currentDate)
                    .map((event) => event.location)
            ),
        ]
            .filter((location) => location)
            .sort(); // Filter out empty locations
    }, [events, currentDate]);

    return (
        <div className="cards" id="meets">
            <span className="subheading">Start your engines</span>
            <h2>Find Car Meets</h2>
            <p>
                Looking for your next outing with the crew? View the latest car
                meets.
            </p>

            <select
                value={currentLocation}
                onChange={(e) => setCurrentLocation(e.target.value)}
            >
                <option value="">All Locations</option>
                {uniqueLocations.map((location) => (
                    <option key={location} value={location}>
                        {location}
                    </option>
                ))}
            </select>

            <div
                className={
                    "cards__content meets" +
                    (paginatedUpcomingEvents.length <= 3 ? " cards-center" : "")
                }
            >
                {paginatedUpcomingEvents.length > 0 ? (
                    paginatedUpcomingEvents.map((event) => (
                        <div className="card" key={event.id}>
                            <div className="card--badge verified">
                                <i className="fa-solid fa-check"></i>Verified
                            </div>
                            {(() => {
                                const eventDate = new Date(event.event_date);
                                const tomorrowDate = new Date(
                                    currentDate.getFullYear(),
                                    currentDate.getMonth(),
                                    currentDate.getDate() + 1
                                );

                                if (
                                    eventDate.getTime() > currentDate.getTime()
                                ) {
                                    return (
                                        <div className="card--badge">
                                            Upcoming
                                        </div>
                                    );
                                } else if (
                                    eventDate.getDate() ===
                                        tomorrowDate.getDate() &&
                                    eventDate.getMonth() ===
                                        tomorrowDate.getMonth() &&
                                    eventDate.getFullYear() ===
                                        tomorrowDate.getFullYear()
                                ) {
                                    return (
                                        <div className="card--badge">
                                            Tomorrow
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="card--badge">Today</div>
                                    );
                                }
                            })()}
                            {event.img ? (
                                <div className="card__img">
                                    <LazyLoadImage
                                        src={event.img}
                                        alt="Featured event"
                                        effect="blur"
                                    ></LazyLoadImage>
                                </div>
                            ) : (
                                <div
                                    className="card__img default"
                                    style={{
                                        backgroundImage: `url(${logo})`,
                                    }}
                                />
                            )}
                            <div className="card__content">
                                <div className="card__info">
                                    <h4>{event.title}</h4>
                                    {event.location && (
                                        <p className="location">
                                            {event.location}
                                        </p>
                                    )}
                                </div>
                                <div className="card__info date">
                                    <p>
                                        {format(
                                            new Date(
                                                new Date(
                                                    event.event_date
                                                ).toLocaleString("en-US", {
                                                    timeZone: "UTC",
                                                })
                                            ),
                                            "MMMM dd, yyyy"
                                        )}
                                    </p>
                                    {event.event_time && (
                                        <p>• {event.event_time}</p>
                                    )}
                                </div>
                                <div className="card__info description">
                                    <p>{event.description}</p>
                                </div>
                                {event.contact_name && event.contact_number ? (
                                    <div className="card__info contact">
                                        <a
                                            href={`tel:${event.contact_number}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fa-regular fa-user"></i>
                                            Contact
                                            {event.contact_name}
                                        </a>
                                    </div>
                                ) : null}
                                <div className="card__info directions">
                                    {event.directions && (
                                        <a
                                            href={event.directions}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <button>Get Directions</button>
                                        </a>
                                    )}
                                    {event.directions && event.event_page && (
                                        <p>•</p>
                                    )}
                                    {event.event_page && (
                                        <>
                                            <a
                                                href={event.event_page}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <button>Learn More</button>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="error">
                        <p>No events found</p>
                    </div>
                )}
            </div>

            <div className="pagination">
                <button
                    disabled={currentPageUpcoming === 1}
                    onClick={() => {
                        setCurrentPageUpcoming(currentPageUpcoming - 1);
                        document
                            .getElementById("meets")
                            .scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    Prev
                </button>
                {Array.from(
                    {
                        length: Math.ceil(
                            filteredUpcomingEvents.length / pageSize
                        ),
                    },
                    (_, index) => (
                        <button
                            key={index + 1}
                            className={`number ${
                                Math.ceil(
                                    filteredUpcomingEvents.length / pageSize
                                ) === 1
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() => {
                                setCurrentPageUpcoming(index + 1);
                                document
                                    .getElementById("meets")
                                    .scrollIntoView({ behavior: "smooth" });
                            }}
                        >
                            {index + 1}
                        </button>
                    )
                )}
                <button
                    disabled={
                        currentPageUpcoming ===
                            Math.ceil(
                                filteredUpcomingEvents.length / pageSize
                            ) || paginatedUpcomingEvents.length < 6
                    }
                    onClick={() => {
                        setCurrentPageUpcoming(currentPageUpcoming + 1);
                        document
                            .getElementById("meets")
                            .scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    Next
                </button>
            </div>

            <h2>Past Car Meets</h2>

            <select
                value={currentLocationPast}
                onChange={(e) => setCurrentLocationPast(e.target.value)}
            >
                <option value="">All Locations</option>
                {uniqueLocationsPast.map((location) => (
                    <option key={location} value={location}>
                        {location}
                    </option>
                ))}
            </select>

            <div
                className={
                    "cards__content past" +
                    (paginatedPastEvents.length <= 3 ? " cards-center" : "")
                }
                id="past"
            >
                {paginatedPastEvents.map((event) => (
                    <div className="card" key={event.id}>
                        <div className="card--badge verified">
                            <i className="fa-solid fa-check"></i>Verified
                        </div>
                        <div className="card--badge">Past event</div>
                        {event.img ? (
                            <div className="card__img">
                                <LazyLoadImage
                                    src={event.img}
                                    alt="Featured event"
                                    effect="blur"
                                ></LazyLoadImage>
                            </div>
                        ) : (
                            <div
                                className="card__img default"
                                style={{
                                    backgroundImage: `url(${logo})`,
                                }}
                            />
                        )}
                        <div className="card__content">
                            <div className="card__info">
                                <h4>{event.title}</h4>
                                {event.location && (
                                    <p className="location">{event.location}</p>
                                )}
                            </div>
                            <div className="card__info date">
                                <p>
                                    {format(
                                        new Date(
                                            new Date(
                                                event.event_date
                                            ).toLocaleString("en-US", {
                                                timeZone: "UTC",
                                            })
                                        ),
                                        "MMMM dd, yyyy"
                                    )}
                                </p>
                                {event.event_time && (
                                    <p>• {event.event_time}</p>
                                )}
                            </div>
                            <div className="card__info description">
                                <p>{event.description}</p>
                            </div>
                            {event.contact_name && event.contact_number ? (
                                <div className="card__info contact">
                                    <a
                                        href={`tel:${event.contact_number}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className="fa-regular fa-user"></i>
                                        Contact
                                        {event.contact_name}
                                    </a>
                                </div>
                            ) : null}
                            <div className="card__info directions">
                                <a
                                    href={event.directions}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <button>Get Directions</button>
                                </a>
                                {event.event_page && (
                                    <>
                                        <p>•</p>
                                        <a
                                            href={event.event_page}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <button>Learn More</button>
                                        </a>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button
                    disabled={currentPagePast === 1}
                    onClick={() => {
                        setCurrentPagePast(currentPagePast - 1);
                        document
                            .getElementById("past")
                            .scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    Prev
                </button>
                {Array.from(
                    {
                        length: Math.ceil(filteredPastEvents.length / pageSize),
                    },
                    (_, index) => (
                        <button
                            key={index + 1}
                            className={`number ${
                                currentPagePast === index + 1 ||
                                Math.ceil(
                                    filteredPastEvents.length / pageSize
                                ) === 1
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() => {
                                setCurrentPagePast(index + 1);
                                document
                                    .getElementById("past")
                                    .scrollIntoView({ behavior: "smooth" });
                            }}
                        >
                            {index + 1}
                        </button>
                    )
                )}
                <button
                    disabled={
                        currentPagePast ===
                        Math.ceil(filteredPastEvents.length / pageSize)
                    }
                    onClick={() => {
                        setCurrentPagePast(currentPagePast + 1);
                        document
                            .getElementById("past")
                            .scrollIntoView({ behavior: "smooth" });
                    }}
                >
                    Next
                </button>
            </div>

            <div className="subscript">
                <p>
                    <i className="fa-solid fa-check verified"></i>denotes
                    verified event by carspace.
                </p>
                <p>
                    Please exercise responsibility, prioritize safety, comply
                    with all laws, show respect for property, and extend
                    gratitude to your event organizers.
                </p>
            </div>
        </div>
    );
}

export default Cards;

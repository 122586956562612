import React from "react";
import "./Footer.scss";
import logo from "../../assets/CARSPACE-black.svg";
import react from "../../assets/react.png";
import supabase from "../../assets/supabase.png";
import netlify from "../../assets/netlify.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <img className="footer--logo" src={logo} alt="CARSPACE logo" />
      <div>
        <ul>
          <li>
            Copyright &copy; {currentYear} carspace | All Rights Reserved
          </li>
          <li>
            <div className="small-badges">
              Powered by
              <a
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer--logo-sm resized react"
                  src={react}
                  alt="React logo"
                  style={{ height: 18, width: 18 }} 
                />
              </a>
              +
              <a
                href="https://supabase.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer--logo-sm supabase"
                  src={supabase}
                  alt="Supabase logo"
                  style={{ width: 75, height: 15 }} 
                />
              </a>
              +
              <a
                href="https://netlify.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer--logo-sm resized netlify"
                  src={netlify}
                  alt="Netlify logo"
                  style={{ height: 18, width: 18 }} 
                />
              </a>
            </div>
          </li>
          <li>
            Made in Canada 🇨🇦
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState } from "react";
import "./Form.scss";
import emailjs from "@emailjs/browser";

export const Form = ({ userEmail }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({
        user_name: "",
        user_email: userEmail || "", // Set the initial value to the user's email
        message: "",
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value ? "" : `The ${name.replace("_", " ")} is required.`,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const { user_name, user_email, message } = formValues;
        let errorMessages = {};
        if (!user_name) errorMessages.user_name = "The name is required.";
        if (!user_email) errorMessages.user_email = "The email is required.";
        if (!message) errorMessages.message = "The message is required.";

        if (Object.keys(errorMessages).length === 0) {
            const templateParams = {
                user_name,
                user_email,
                message,
            };

            emailjs
                .send(
                    "service_0lmqckm",
                    "template_2noai5h",
                    templateParams,
                    "3DP1dIstbBmoVANZP"
                )
                .then(
                    () => {
                        console.log("Email sent!");
                        setIsSubmitted(true);
                    },
                    (error) => {
                        console.log("Email failed to send.", error);
                    }
                );
        } else {
            setErrors(errorMessages);
        }
    };

    return (
        <div className="form" id="form">
            <h2>Ready to Share?</h2>
            <p>Submit a request today to get featured.</p>
            {isSubmitted ? (
                <div className="success-message">
                    <div className="verified">
                        <i className="fa-solid fa-check"></i>
                    </div>
                    <p>Request sent successfully!</p>
                </div>
            ) : (
                <form onSubmit={sendEmail}>
                    <label>Name</label>
                    <input
                        type="text"
                        name="user_name"
                        placeholder="Enter your name"
                        value={formValues.user_name}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.user_name && (
                        <p className="error">{errors.user_name}</p>
                    )}

                    <label>Email</label>
                    <input
                        type="email"
                        name="user_email"
                        placeholder="Enter your email"
                        value={formValues.user_email}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.user_email && (
                        <p className="error">{errors.user_email}</p>
                    )}

                    <label>Message</label>
                    <textarea
                        name="message"
                        placeholder="Enter your event details"
                        value={formValues.message}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                    {errors.message && (
                        <p className="error">{errors.message}</p>
                    )}

                    <input type="submit" value="Send" className="button" />
                </form>
            )}
            <p className="subscript">
                Requests will be subject to verification, and if successful,
                approved within 1 business day.
            </p>
        </div>
    );
};

export default Form;

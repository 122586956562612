import React from "react";
import "./About.scss";

function About() {
  return (
    <div className="about" id="about">
      <span className="subheading">Our common meeting place</span>
      <h2>Cars & Coffee</h2>
      <p>
        The ultimate fusion of horsepower and caffeine, where the roar of
        engines meets the aroma of freshly brewed coffee. It's the place where
        car aficionados gather at dawn to showcase their prized rides, from
        classic beauties to modern marvels, while indulging in the camaraderie
        of fellow enthusiasts. Against the backdrop of a sunrise-lit parking
        lot, conversations flow as smoothly as the espresso, with discussions
        ranging from engine specs to restoration techniques.
      </p>
    </div>
  );
}

export default About;

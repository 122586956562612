import React from "react";
import "./Sponsors.scss";

// import logo1 from "../../assets/Sponsors/dentsmart.png";
// import logo2 from "../../assets/Logos/logoImg2.svg";
// import logo3 from "../../assets/Logos/logoImg3.svg";
// import logo4 from "../../assets/Logos/logoImg4.svg";
// import logo5 from "../../assets/Logos/logoImg5.svg";

// const logos = [{ logo: logo1, url: "https://dentsmartinc.ca" }];

function Sponsors() {
  return (
    <div className="sponsors">
      <span className="subheading">A Shared Passion That Drives Us</span>
      <h2>Sponsors</h2>
      <p>Stay tuned for our first set of event sponsors.</p>
      {/* <p>Become a sponsor and a supporter today.</p> */}
      {/* <div className="sponsors--logos">
        {logos.map((item, index) => (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <img
              key={index}
              src={item.logo}
              alt="Sponsor Logo"
              className="sponsors--logo-item"
            />
          </a>
        ))}
      </div> */}
    </div>
  );
}

export default Sponsors;

import React from "react";
import "./Hero.scss";
import heroImg from "../../assets/Hero/heroImg2.webp";
import logo from "../../assets/CARSPACE.svg";

function Hero() {
    function handleClickMeets() {
        const element = document.getElementById("meets");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    function handleClickForm() {
        const element = document.getElementById("form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <div className="hero" style={{ backgroundImage: `url(${heroImg})` }}>
            <span className="hero--title">Welcome to</span>
            <img
                className="hero--logo"
                src={logo}
                alt="carspace logo"
                preload="true"
                style={{ width: 306, height: 43 }}
            ></img>
            <div className="hero__content">
                <p className="hero--subtitle">
                    The ultimate resource for finding car meets.
                </p>
                <div className="hero--buttons">
                    <button type="button" onClick={handleClickMeets}>
                        See Latest Events
                    </button>
                    <button type="button" onClick={handleClickForm}>
                        Submit An Event
                    </button>
                </div>
            </div>
            <div
                className="hero--button"
                onClick={() =>
                    window.scrollTo({
                        top: document.getElementById("about").offsetTop,
                        behavior: "smooth",
                    })
                }
            >
                <i className="fa-solid fa-arrow-down"></i>
            </div>
        </div>
    );
}

export default Hero;

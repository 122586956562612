import React from "react";
import "./CTA.scss";
import ctaImg from "../../assets/CTA/ctaImg1.jpg";

function CTA() {
  return (
    <div className="cta" style={{ backgroundImage: `url(${ctaImg})` }}>
      <span className="subheading">Powered by the community</span>
      <h2>Add the Next Big Meet</h2>
      <p>
        carspace is powered by enthusiam and passion. Because our
        platform is open, you can request to add any kind of automobile-related event.
      </p>
      <a href="#form">
        <button>Add an Event</button>
      </a>
    </div>
  );
}

export default CTA;

import React from "react";
import "./App.scss";

import About from "./components/About/About";
import Cards from "./components/Cards/Cards";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import CTA from "./components/CTA/CTA";
import Sponsors from "./components/Sponsors/Sponsors";
import Form from "./components/Form/Form";
// import { useState, useEffect } from "react";
// import { createClient } from "@supabase/supabase-js";
// import { Auth } from "@supabase/auth-ui-react";

// const supabase = createClient(
//     "https://glwkzfeedpabgtdnzqqx.supabase.co",
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imdsd2t6ZmVlZHBhYmd0ZG56cXF4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ0OTkwMjQsImV4cCI6MjAzMDA3NTAyNH0.VxkAUuYERgJT9qllt5GQZET5S8mU2v_NESPuTiNRHJA"
// );

function App() {
    return (
        <div className="App">
            <Hero />
            <About />
            <Cards />
            <CTA />
            <Sponsors />
            <Form />
            <Footer />
        </div>
    );
}
export default App;
